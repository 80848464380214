import * as React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { Stepper } from './Stepper'
import { Buttons } from './Buttons'
import { useSignUp } from './useSignUp'
import { InfoStep } from './InfoStep'
import { PlanStep } from './PlanStep'
import { ConfirmStep } from './ConfirmStep'
import { ConfirmDetails } from './ConfirmDetails'
import { PlanSummary } from './PlanSummary'

export function SignUpLayout() {
  const { step, isSuccess } = useSignUp()

  return (
    <>
      <Box component="nav" sx={{ textAlign: 'center', p: 2 }}>
        <a href="https://www.basebuilders.com">
          <img
            alt="Base Builders: Project Management Software for AE Firms"
            src="/img/logo/logo_tagline.png"
          />
        </a>
      </Box>

      {isSuccess ? (
        <Container maxWidth="sm" sx={{ pb: 4 }}>
          <Typography variant="h1" align="center" sx={{ mb: 2 }}>
            Thank you and welcome to Base Builders!
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Here's what happens next:
              </Typography>
              <ol>
                <li><Typography>Someone from our team will contact you to begin the onboarding process.</Typography></li>
                <li><Typography>In the meantime, our team will get your account server created in the next two business days.</Typography></li>
                <li><Typography>After your account is created, you will receive an account activation email.</Typography></li>
              </ol>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">
                If you have any questions please reach out:
              </Typography>

              <ul>
                <li>
                  <Typography>
                    Call us at: <a href="tel:17758523000">+1-775-852-3000</a>
                  </Typography>
                </li>

                <li>
                  <Typography>
                    Send an email to: <a href="mailto:support@basebuilders.com">support@basebuilder.com</a>
                  </Typography>
                </li>
              </ul>

            </Grid>

            <Grid item xs={12}>
              <ConfirmDetails />
            </Grid>

            <Grid item xs={12}>
              <PlanSummary />
            </Grid>
          </Grid>

        </Container>
      ) : (
        <Container maxWidth="md" sx={{ pb: 4 }}>
          <Typography variant="h1" component="h1">
            Sign Up
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <Stepper />

          <Box sx={{ mt: 2 }} />

          {step === 1 && <InfoStep />}

          {step === 0 && <PlanStep />}

          {step === 2 && <ConfirmStep />}

          <Box sx={{ mb: 2 }} />

          <Buttons />
        </Container>
      )}
    </>
  )
}

SignUpLayout.propTypes = {

}
