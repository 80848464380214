import * as React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from '@material-ui/core/ButtonBase'
import { useSignUp } from './useSignUp'


export function Step({ children, value }) {
  const { step, completeSteps, setStep } = useSignUp()

  const isLeft = value === 0
  const isRight = value === 2
  const isSelected = value === step

  // A step is disabled if any of the previous steps are not complete, and
  // the value is not the current step.
  const isDisabled = value === 0
    ? false
    : value === 1
    ? !completeSteps[0]
    : !completeSteps[0] || !completeSteps[1]

  return (
    <ButtonBase
      sx={{
        flex: 1,
        height: 48,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isSelected ? 'primary.main' : 'primary.50',
        color: isSelected ? 'common.white' : 'text.secondary',
        borderTopLeftRadius: isLeft ? 16 : 0,
        borderBottomLeftRadius: isLeft ? 16 : 0,
        borderTopRightRadius: isRight ? 16 : 0,
        borderBottomRightRadius: isRight ? 16 : 0,
        typography: 'h3',
        position: 'relative',
        '&.Mui-disabled': {
          backgroundColor: 'grey.100',
          color: 'grey.500',
        },
      }}
      disabled={isDisabled}
      onClick={() => setStep(value)}
    >
      {children}
    </ButtonBase>

  )
}

Step.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOf([0, 1, 2]),
}
