import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { Step } from './Step'


export function Stepper() {

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={4}>
        <Step value={0}>1. Select your plan</Step>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Step value={1}>2. Contact information</Step>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Step value={2}>3. Confirm and pay</Step>
      </Grid>
    </Grid>
  )
}
