import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { PlanSummary } from './PlanSummary'
import { ConfirmDetails } from './ConfirmDetails'
import StripeCardField from 'src/components/StripeCardField'
import { useSignUp } from './useSignUp'
import Checkbox from 'genjo-ui/core/Checkbox'
import { firebaseFunctions } from 'src/services/firebase'
import { ACH } from './ACH'
import { Button, Collapse } from '@material-ui/core'
import { Group, Text } from '@mantine/core'
import { CheckCircleOutline } from '@material-ui/icons'


export function ConfirmStep() {
  const {
    values,
    touched,
    setFieldTouched,
    setFieldValue,
    paymentInfo,
    setPaymentInfo,
    error,
    serviceTermsAccepted,
    setServiceTermsAccepted,
    paymentTermsAccepted,
    setPaymentTermsAccepted,
    setCoupon,
    coupon,
    promoCode,
    setPromoCode,
    hasPromoCode,
    setHasPromoCode,
    promoCodeError,
    setPromoCodeError,
  } = useSignUp()

  const [paymentMethod, ] = React.useState('cc')



  async function applyPromoCode() {
    if (hasPromoCode && promoCode) {
      const checkPromoCode = firebaseFunctions.httpsCallable('checkPromoCode')

      try {
        const { data } = await checkPromoCode({
          promoCode: promoCode,
          accountType: values.accountType,
        })

        if (data.status === 'error') {
          setPromoCodeError('Invalid promo code')
          return
        }

        setPromoCodeError('')
        setCoupon(data.promoCode.coupon)
        // if (data.error) {
        //   setHasPromoCode(false)
        //   setFieldValue('promoCode', '')
        //   setError(data.error)
        // } else {
        //   setValues({
        //     ...values,
        //     ...data,
        //   })
        // }
      } catch (err) {
        console.log({ err })
        setPromoCodeError('Invalid promo code')
      }
    }
  }

  function cardHolderNameRequiredMixin() {
    const hasError = !paymentInfo.cardHolderName && touched.has('cardHolderName')

    return {
      onBlur: () => setFieldTouched('cardHolderName'),
      error: hasError,
      helperText: hasError ? 'Required' : '',
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ConfirmDetails />
        </Grid>

        <Grid item xs={12}>
          <PlanSummary />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Payment
              </Typography>

              {/* <SimpleTabs value={paymentMethod} onChange={handlePaymentMethodChange}>
                <SimpleTab value="ach" label="ACH / Checking Account" />
                <SimpleTab value="cc" label="Credit Card" />
              </SimpleTabs> */}

              <Grid container spacing={2} sx={paymentMethod !== 'cc' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
                <Grid item xs={12}>
                  <TextField
                    label="Card Holder Name"
                    autoComplete="cc-name"
                    value={values.cardHolderName}
                    onChange={event => setPaymentInfo({
                      ...paymentInfo,
                      cardHolderName: event.target.value,
                    })}
                    required
                    {...cardHolderNameRequiredMixin()}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StripeCardField
                    onChange={cardInfo => setPaymentInfo({
                      ...paymentInfo,
                      cardInfo,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  {coupon ? (
                    <Group spacing="xs">
                      <CheckCircleOutline size={16} style={{ color: 'green' }} />
                      <Text>Promo Code Applied!</Text>
                    </Group>
                  ) : (
                    <FormControlLabel
                      sx={{ mt: 2 }}
                      control={
                        <Checkbox
                          checked={hasPromoCode || Boolean(values.promoCode)}
                          onChange={event => {
                            setHasPromoCode(event.target.checked)
                            setFieldValue('promoCode', '')
                          }}
                        />
                      }
                      label={"Have a promo code?"}
                    />
                  )}

                  <Collapse in={!coupon && (hasPromoCode || Boolean(promoCode))}>
                      <TextField
                        sx={{ mt: 2 }}
                        autoFocus
                        label="Promo Code"
                        value={promoCode}
                        onChange={event => setPromoCode(event.target.value.toUpperCase().trim())}
                        error={Boolean(promoCodeError)}
                        helperText={promoCodeError}
                      />

                      <Button
                        sx={{ mt: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={applyPromoCode}
                        disabled={!promoCode}
                      >
                        Apply Promo Code
                      </Button>
                  </Collapse>
                </Grid>


                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <Checkbox
                        checked={paymentTermsAccepted}
                        onChange={event => setPaymentTermsAccepted(event.target.checked)}
                      />
                    }
                    label={`I (${values.fullName} on behalf of ${values.companyName}) authorize Base Builders LLC to charge this credit card as described in the summary above.`}
                  />
                </Grid>
              </Grid>



              <Grid container spacing={2} sx={paymentMethod !== 'ach' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
                <Grid item xs={12}>
                  <ACH />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Terms and Conditions
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceTermsAccepted}
                    onChange={event => setServiceTermsAccepted(event.target.checked)}
                  />
                }
                label={
                  <Typography>I have read and agree to the <a href="https://www.basebuilders.com/terms" rel="noopener noreferrer" target="_blank">Terms and Conditions</a>.</Typography>
                }
              />
            </CardContent>
          </Card>
        </Grid>

        {Boolean(error) && (
          <Grid item xs={12}>
            <Typography variant="h6" color="error.main" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  )
}


ConfirmStep.propTypes = {

}
