import * as React from 'react'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import { SignUpContext } from './SignUpContext'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'


const REQUIRED_INFO_FIELDS = [
  'companyName', 'email', 'phoneNumber', 'address', 'fullName',
]


const ONBOARDING_PRICE = 37900


function usePrices(accountType) {
  const [prices, setPrices] = React.useState(null)

  React.useEffect(
    () => {
      let isMounted = true

      ;(async () => {
        const result = {
          MONTHLY: { FINANCIAL: 0, PRODUCTION: 0, LIMITED: 0 },
          YEARLY: { FINANCIAL: 0, PRODUCTION: 0, LIMITED: 0 },
        }

        const priceSnaps = await firebaseDb.collection('subscriptionPrices').limit(1000).get()
        priceSnaps.docs.forEach(doc => {
          const data = doc.data()

          if (data.accountType === accountType && data.isDefault) {
            result[data.billingInterval][data.accessLevel] = data.amount
          }
        })

        if (isMounted) {
          setPrices(result)
        }
      })()

      return () => isMounted = false
    },
    [accountType]
  )

  return prices
}



export function SignUpProvider({ children, accountType }) {
  const elements = useElements()
  const stripe = useStripe()
  const loader = useLoader()
  const snackbar = useSnackbar()
  const [coupon, setCoupon] = React.useState(null)

  const [plaidToken, setPlaidToken] = React.useState(null)
  const [plaidInfo, setPlaidInfo] = React.useState(null)
  const [paymentTermsAccepted, setPaymentTermsAccepted] = React.useState(false)
  const [serviceTermsAccepted, setServiceTermsAccepted] = React.useState(false)

  const prices = usePrices(accountType)

  const [error, setError] = React.useState(null)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const [step, setStep] = React.useState(0)
  React.useEffect(
    () => {
      window.scrollTo({ top : 0 })
    },
    [step, isSuccess]
  )

  const [hasPromoCode, setHasPromoCode] = React.useState(false)
  const [promoCode, setPromoCode] = React.useState('')
  const [promoCodeError, setPromoCodeError] = React.useState('')

  const [values, setValues] = React.useState({
    accountType,

    // Account Info
    clientId: uuid(),
    companyName: '',
    fullName: '',
    discipline: '',
    email: '',
    phoneNumber: '',
    address: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',

    // Plan Info
    billingInterval: 'MONTHLY',
    financialUsersLicenseCount: 1,
    productionUsersLicenseCount: 0,
    limitedUsersLicenseCount: 0,
  })

  const [paymentInfo, setPaymentInfo] = React.useState({
    cardInfo: null,
    cardHolderName: null,
  })

  const [touched, setTouched] = React.useState(new Set())

  function setFieldTouched(field) {
    setTouched(t => {
      const newTouched = new Set(t)
      newTouched.add(field)
      return newTouched
    })
  }

  const completeSteps = React.useMemo(
    () => {
      const infoStepIsComplete = REQUIRED_INFO_FIELDS.every(field => Boolean(values[field]))

      const achIsComplete = plaidInfo && paymentTermsAccepted

      const creditCardInfoIsComplete = Boolean(paymentInfo.cardHolderName)
        && Boolean(paymentInfo.cardInfo?.complete)
        && !paymentInfo.cardInfo?.error
        && paymentTermsAccepted

      const paymentInfoIsComplete = serviceTermsAccepted
        && (achIsComplete || creditCardInfoIsComplete)
        && !(hasPromoCode && !coupon)

      return [true, infoStepIsComplete, paymentInfoIsComplete]
    },
    [values, paymentInfo, paymentTermsAccepted, plaidInfo, serviceTermsAccepted, hasPromoCode, coupon]
  )

  const [isRequestingActivation, setIsRequestingActivation] = React.useState(false)

  function requestActivation() {
    setIsRequestingActivation(true)
  }

  function cancelActivationRequest() {
    setIsRequestingActivation(false)
  }

  function setFieldValue(field, newValue) {
    setValues(v => ({ ...v, [field]: newValue }))
  }

  function handleNext() {
    setStep(s => Math.min(2, s + 1))
  }

  function handlePrev() {
    setStep(s => Math.max(0, s - 1))
  }

  // async function getPaymentIntent({ amount }) {
  //   const getNewAccountPaymentIntent = firebaseFunctions.httpsCallable(
  //     'getNewAccountPaymentIntent'
  //   )

  //   const paymentIntent = await getNewAccountPaymentIntent({ amount })

  //   console.log({ paymentIntent })
  // }
  async function getPlaidLink() {
    const requestPlaidLink = firebaseFunctions.httpsCallable('requestPlaidLink')

    try {
      loader.open('Requesting ACH verification link...')
      const response = await requestPlaidLink({
        clientUserId: values.clientId,
        email: values.email,
        name: values.companyName,
      })

      if (response?.data?.status !== 'success') {
        throw new Error('Something went wrong.')
      }

      const { token } = response.data
      loader.close()
      setPlaidToken(token)
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? error ?? 'Something went wrong requesting link.')
    } finally {
      loader.close()
    }
  }

  async function activateAccount() {
    loader.open('Creating account...')

    const getOrCreateSignUpAccount = firebaseFunctions.httpsCallable(
      'getOrCreateSignUpAccount'
    )

    try {
      const response = await getOrCreateSignUpAccount({ values, plaidInfo, couponId: coupon?.id })

      if (response?.data?.status !== 'success') {
        throw new Error('Something went wrong.')
      }

      const { clientSecret } = response.data

      const completeSignUp = firebaseFunctions.httpsCallable(
        'completeSignUp'
      )

      if (!plaidInfo) {
        const paymentResult = await stripe.confirmCardPayment(clientSecret, {
          receipt_email: values.email,
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: paymentInfo.cardHolderName,
            },
          }
        })

        if (paymentResult.error) {
          snackbar.error(paymentResult.error?.message ?? 'Something went wrong.')
          return setError(paymentResult.error?.message ?? 'Something went wrong.')
        }

        if (paymentResult.paymentIntent.status === 'succeeded') {
          await completeSignUp({ email: values.email })
          snackbar.success('Account created.')
          return setIsSuccess(true)
        }
      } else {
        await completeSignUp({ email: values.email })
        snackbar.success('Account created.')
        return setIsSuccess(true)
      }
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? error ?? 'Something went wrong creating account.')
    } finally {
      loader.close()
    }
  }

  return (
    <SignUpContext.Provider
      value={{
        values,
        setFieldValue,
        setValues,
        handleNext,
        handlePrev,
        step,
        completeSteps,
        setStep,
        touched,
        setFieldTouched,
        userPrices: prices,
        onboardingPrice: ONBOARDING_PRICE,
        isRequestingActivation,
        requestActivation,
        cancelActivationRequest,
        // getPaymentIntent,
        activateAccount,
        paymentInfo,
        setPaymentInfo,
        isSuccess,
        error,
        getPlaidLink,
        plaidToken,
        plaidInfo,
        setPlaidInfo,
        setPlaidToken,
        paymentTermsAccepted,
        setPaymentTermsAccepted,
        serviceTermsAccepted,
        setServiceTermsAccepted,
        setCoupon,
        coupon,
        hasPromoCode,
        setHasPromoCode,
        promoCode,
        setPromoCode,
        promoCodeError,
        setPromoCodeError,
      }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

SignUpProvider.propTypes = {
  /** The wrapped content of the provider. */
  children: PropTypes.node,
  accountType: PropTypes.oneOf(['DEMO', 'LIVE']),
}
